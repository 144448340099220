import React from 'react'
import { Box, Grid, Typography } from "@mui/material"

export default function WelcomePage() {
    return (

        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                minHeight: '100vh',
                background: 'radial-gradient(circle, rgba(2,0,36,0) 0%, rgba(188,93,67,0.7455357142857143) 98%, rgba(188,93,67,1) 100%)'
            }}
        >
            <Grid item xs={3}><Box component='img' src='/img/belka_1.jpg' sx={{ height: '300px', border: '10px solid #43a2bc' }} /></Grid>
            <Grid item xs={3}>
                <Typography variant='h4' sx={{ textAlign: 'center' }}>This site is under construction</Typography>
            </Grid>
        </Grid>
    )
}